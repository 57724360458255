export class ImagePositionsDescriptionModel {
  constructor() { }
    id: number;
    description: string = null;
    // tslint:disable-next-line:variable-name
    image_position_rule_id: number = null;
    position: number = null;
    src: string = null;
    imgID: number = null;
     // tslint:disable-next-line:variable-name
    created_at: Date = null;
    // tslint:disable-next-line:variable-name
    updated_at: Date = null;
    // tslint:disable-next-line:variable-name
  }
