import { Injectable } from '@angular/core';

// config
import { AppConfig } from '../app.config';

// rxjs
import { map } from 'rxjs/operators';

// services
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class VehicleMakeService {

  constructor(private dataService: DataService) { }

  getVehicleMakes() {
    const request = {
      path: AppConfig.Api.VehicleMake.list
    };

    return this.dataService.get(request)
          .pipe(map(res => {
            return res;
          }));
  }
}
